<template>
    <div style="height: 100vh; width: 100vw; min-height: 800px; overflow: hidden; position: relative; ">

        <video id="v1" autoplay loop muted>
            <source src="https://cesium-1255710621.cos.ap-nanjing.myqcloud.com/login_bg.mp4" type="video/mp4"/>
        </video>
        <div style="height: 100vh; width: 100vw; background: rgba(0,0,0, 0); backdrop-filter: blur(10px);"></div>
        <div style="position: absolute; left: 50%; top: 80px; transform: translateX(-50%)">
            <v-container
                    style="letter-spacing:14px;color: #e2e8f1;font-family: standard; font-size: 52px; text-align: center; line-height: 80px; text-shadow: 0 0 1px #fff; user-select: none">
                中国民航大学<br/>空中交通管理仿真和演示平台
            </v-container>
            <v-card style="background: rgba(249, 249, 249, .5); margin: 0px auto; margin-top: 120px" class=""
                    width="500">
                <v-card-title> {{$t("auth.login")}}</v-card-title>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="username"
                                        :label="$t('auth.username')"
                                        @input="show=false"
                                        :rules="nameRules"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        @keydown.enter="validate"
                                        v-model="password"
                                        :label="$t('auth.password')"
                                        @input="show=false"
                                        :rules="pasRules"
                                        type="password"
                                        required
                                ></v-text-field>
                            </v-col>

                        </v-row>
                        <span v-show="show" class="error">用户名或密码错误</span>
                        <v-card-actions class="mt-2">
                            <!-- <router-link to="register">没有密码？注册</router-link> -->
                            <v-spacer></v-spacer>
                            <v-btn small color="primary" text @click="reset">{{$t('auth.reset')}}</v-btn>
                            <v-btn small color="primary" text @click="validate">{{$t('auth.login')}}</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-container>
            </v-card>
        </div>
        <div class="bottom--text">
            CAUC-ENAC联合空管应用数学研究中心
        </div>
    </div>
</template>

<script>
    import {login} from '@/api/auth'

    export default {
        data() {
            return {
                valid: true,
                username: null,
                password: null,
                show: false,
                nameRules: [(v) => !!v || "username is required"],
                pasRules: [(v) => !!v || "password is required"],
                referent: undefined,
            };
        },
        mounted() {
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            this.referent = this.$route?.query?.referent
        },
        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    login({username: this.username, password: this.password}).then((res) => {
                        localStorage.setItem('token', `Bearer ${res.token}`)
                        this.$store.commit('set_user_info', res.user)
                        localStorage.setItem('user_info', JSON.stringify(res.user))
                        this.referent ? this.$router.push({name: this.referent}) : this.$router.push({name: 'airportList'});
                        return
                    }).catch(e => {
                        return this.$toast({
                            title: this.$t('error'),
                            message: this.$t('auth.fail_log'),
                            type: 'warning'
                        })
                    })
                }
            },
            reset() {
                this.$refs.form.reset();
            },
        },
    };
</script>
<style lang="stylus" scoped>
    .active {
        margin: 200px auto;
    }

    .error {
        color: red;
        font-size: 12px;
    }

    video {
        position: fixed;
        right: 0px;
        bottom: 0px;
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto;
        z-index: -11
    }

    source {
        height: auto;
        width: auto;
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto;
    }

    .bottom--text
        text-align: center;
        font-size: 18px;
        user-select: none;
        min-width: 300px;
        background: rgba(249, 249, 249, .2);
        height: 40px;
        line-height: 40px;
        padding: 0px 20px 0px 20px;
        position: absolute;
        border-radius: 20px;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 0.95)
        transition all ease .4s

        &:hover
            box-shadow 0 0 10px #ddd
            transition all ease .4s
</style>;
